import React, { useState } from 'react';

import { FromDataProps } from './types';

import CompanyForm from './CompanyForm';
import CreatingForm from './CreatingForm';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';
import UserForm from './UserForm';

type RegisterFormProps = {
  userExternalId?: string;
  userId?: string;
  email?: string;
};

function RegisterForm({ userExternalId = undefined, userId = undefined, email = undefined }: RegisterFormProps) {
  const [activeStep, setActiveStep] = useState(email || userExternalId || userId ? 1 : 0);
  const [formData, setFormData] = useState<FromDataProps>({ userExternalId, userId, email });

  const updateData = (data: object) => {
    setFormData({ ...formData, ...data });
  };

  const handleNext = (data: object) => {
    updateData(data);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handlePrevious = (data: object) => {
    updateData(data);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <>
      {activeStep === 0 && <EmailForm defaultValues={formData} onNext={handleNext} />}
      {activeStep === 1 && <UserForm defaultValues={formData} onNext={handleNext} />}
      {activeStep === 2 && <CompanyForm defaultValues={formData} onPrevious={handlePrevious} onNext={handleNext} />}
      {activeStep === 3 && <PasswordForm defaultValues={formData} onPrevious={handlePrevious} onNext={handleNext} />}
      {activeStep === 4 && (
        <CreatingForm clientId={formData.id || null} clientHash={formData.hash || null} eventId={formData.eventId || null} />
      )}
    </>
  );
}

export default RegisterForm;
