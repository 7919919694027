import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { EventScheduledEvent, InlineWidget, useCalendlyEventListener } from 'react-calendly';

import ApiClient from '../../Common/api-client';

type RequestDemoFormProps = {
  user?: string | null | (string | null)[];
};

function RequestDemoForm({ user = undefined }: RequestDemoFormProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [userName, setUserName] = useState<undefined | string>();
  const [userEmail, setUserEmail] = useState<undefined | string>();

  useEffect(() => {
    if (user) {
      ApiClient.get('request-demo', { params: { user } })
        .then(({ data: responseData }: AxiosResponse) => {
          setUserName(responseData?.name);
          setUserEmail(responseData?.email);
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [user]);

  const onEventScheduled = ({ data }: EventScheduledEvent) => {
    if (data?.payload?.invitee?.uri) {
      ApiClient.post('request-demo', { user, url: data?.payload?.invitee?.uri })
        .then(({ data: responseData }: AxiosResponse) => {
          window.dataLayer.push({ event: 'web.requestDemoSuccess', eventId: responseData?.eventId });
        })
        .catch(() => {});
    }
  };

  useCalendlyEventListener({
    onEventScheduled
  });

  if (loading) return null;

  return (
    <InlineWidget
      url="https://calendly.com/offsoo/online-demo"
      styles={{ height: '100%', width: '100%' }}
      prefill={{
        name: userName,
        email: userEmail
      }}
      pageSettings={{
        hideGdprBanner: true,
        hideEventTypeDetails: true,
        hideLandingPageDetails: true,
        backgroundColor: 'white',
        primaryColor: '4990dd',
        textColor: '4b5563'
      }}
    />
  );
}

export default RequestDemoForm;
