import { Dialog as UiDialog } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ApiClient from '../../Common/api-client';
import { initReCaptcha, setResponseError } from '../../Common/api-helper';

import Alert from '../../../components/Alert';
import { Button } from '../../../components/Button';
import Input from '../../../components/Input';

type RequestDemoFormValueProps = {
  email?: string;
};

const RequestDemoFormSchema = yup.object().shape({
  email: yup.string().required('E-mailadres is verplicht.').email('E-mailadres moet een geldig adres zijn.')
});

function RequestDemoForm() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<RequestDemoFormValueProps>({
    resolver: yupResolver(RequestDemoFormSchema)
  });

  useEffect(() => {
    window.dataLayer.push({ event: 'web.registerByEmailStart' });
  }, []);

  const onSubmit = (formData: RequestDemoFormValueProps): void => {
    setLoading(true);

    initReCaptcha().then(token =>
      ApiClient.put('register', { ...formData, token })
        .then(({ data: responseData }: AxiosResponse) => {
          window.dataLayer.push({ event: 'web.registerByEmailSuccess', eventId: responseData?.eventId });

          navigate('/aanmelden', {
            state: {
              userId: responseData?.userId,
              email: formData.email
            }
          });
        })
        .catch((error: AxiosError) => setResponseError(error, setErrorMessage, setError))
        .finally(() => setLoading(false))
    );
  };

  return (
    <form className="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 lg:grid-cols-3">
        <div className="relative overflow-hidden bg-primary-600 p-6 lg:px-8 lg:py-12">
          <UiDialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
            Gratis uitproberen?
          </UiDialog.Title>
          <div className="mt-1">
            <p className="text-sm text-primary-50">
              Start binnen één minuut met jouw gratis proefperiode en ervaar hoe Offsoo jouw bedrijfsvoering eenvoudiger maakt!
            </p>
          </div>
        </div>

        <div className="p-6 lg:col-span-2 lg:px-8 lg:py-12">
          {errorMessage && <Alert message={errorMessage} type="error" />}

          <div>
            <div>
              <Input
                {...register('email')}
                type="email"
                id="register-by-email-email"
                autoComplete="email"
                placeholder="E-mailadres..."
                labelText="E-mailadres"
                required
                disabled={loading}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
              />
            </div>

            <div className="mt-1">
              <Button type="submit" color="primary" large loading={loading}>
                Nu uitproberen
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default RequestDemoForm;
