import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ApiClient from '../../Common/api-client';
import { initReCaptcha, setResponseError } from '../../Common/api-helper';

import { FromDataProps } from './types';

import Alert from '../../../components/Alert';
import { Button } from '../../../components/Button';
import Input from '../../../components/Input';

interface EmailFormProps {
  defaultValues: FromDataProps;
  onNext: ({}: FromDataProps) => void;
}

const EmailFormSchema = yup.object().shape({
  userId: yup.string(),
  userExternalId: yup.string(),
  email: yup.string().email('E-mailadres moet een geldig adres zijn.').required('E-mailadres is verplicht.')
});

function EmailForm({ defaultValues, onNext }: EmailFormProps) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const {
    register,
    handleSubmit,
    setFocus,
    setError,
    formState: { errors }
  } = useForm<FromDataProps>({
    defaultValues,
    resolver: yupResolver(EmailFormSchema)
  });

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const onSubmit = (formData: FromDataProps): void => {
    setLoading(true);

    initReCaptcha().then(token =>
      ApiClient.put('register', { ...formData, token })
        .then(({ data: responseData }: AxiosResponse) => {
          window.dataLayer.push({ event: 'web.registerByEmailSuccess', eventId: responseData?.eventId });

          onNext({ ...formData, ...responseData });
        })
        .catch((error: AxiosError) => setResponseError(error, setErrorMessage, setError))
        .finally(() => setLoading(false))
    );
  };

  return (
    <form className="form" noValidate onSubmit={handleSubmit(onSubmit)}>
      {errorMessage && <Alert message={errorMessage} type="error" />}

      <h1 className="sr-only">Aanmelden</h1>
      <h2 className="mb-1 text-lg font-bold text-blue-900 sm:text-2xl">Probeer Offsoo 14 dagen gratis</h2>
      <p className="mb-4 text-base text-gray-500">De meest eenvoudige oplossing voor de vooruitgang van jouw bedrijf.</p>

      <div className="space-y-1">
        <Input
          {...register('email')}
          type="email"
          id="register-email"
          autoComplete="email"
          placeholder="naam@bedrijf.com"
          labelText="E-mailadres"
          disabled={loading}
          error={!!errors.email}
          helperText={errors.email && errors.email.message}
        />
      </div>

      <div className="mt-4">
        <Button type="submit" color="primary" large loading={loading}>
          Probeer gratis
        </Button>
      </div>
    </form>
  );
}

export default EmailForm;
