import { Dialog as UiDialog } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';

import PusherClient from '../../Common/pusher-client';

import Alert from '../../../components/Alert';
import { Button } from '../../../components/Button';
import Dialog from '../../../components/Dialog';

interface ProgressBarProps {
  seconds: number;
}
interface CreatingFormProps {
  clientId: number | null;
  clientHash: string | null;
  eventId: string | null;
}

function ProgressBar({ seconds }: ProgressBarProps) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 100 : prevProgress + 1 / seconds));
    }, 10);

    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  return (
    <div className="relative mb-4 pt-1">
      <div className="flex h-2 overflow-hidden rounded bg-primary-200 text-xs">
        <div
          style={{ width: `${progress}%` }}
          className="flex flex-col justify-center whitespace-nowrap bg-primary-500 text-center text-white shadow-none"
        />
      </div>
      <div className="mt-2 text-center">
        <span className="inline-block text-xs font-semibold text-primary-600">{`${Math.round(progress)}%`}</span>
      </div>
    </div>
  );
}

function CreatingForm({ clientId, clientHash, eventId }: CreatingFormProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<null | string>(null);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  useEffect(() => {
    PusherClient.subscribe(`private-client.${clientId}`)
      .bind('creation.success', ({ url: newUrl }: any) => {
        window.dataLayer.push({ event: 'web.requestTrialSuccess', eventId, externalId: clientHash });

        setLoading(false);

        if (newUrl) {
          setUrl(newUrl);
          setOpen(true);
        }
      })
      .bind('creation.failed', ({ error }: any) => {
        setLoading(false);
        setErrorMessage(error || 'Er is iets fout gegaan met het aanmaken van de omgeving.');
      });

    return () => {
      PusherClient.unsubscribe(`private-client.${clientId}`);
    };
  }, [clientId, clientHash, eventId]);

  return (
    <>
      {errorMessage && <Alert message={errorMessage} type="error" />}

      {loading && (
        <div className="py-12">
          <h2 className="text-center text-base font-semibold uppercase tracking-wider text-gray-400">Een klein ogenblik</h2>
          <p className="mb-12 mt-2 text-center text-2xl font-extrabold tracking-tight text-blue-900 sm:text-3xl">
            We zetten alles klaar...
          </p>

          <ProgressBar seconds={9} />
        </div>
      )}

      {!loading && !errorMessage && (
        <div className="py-12">
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center">
            <h2 className="text-center text-base font-semibold uppercase tracking-wider text-gray-400">Omgeving aangemaakt!</h2>
            <div className="mx-auto mt-6 max-w-sm">
              <p className="text-center text-base text-gray-500">
                De omgeving is succesvol aangemaakt en jouw <strong>14-dagen proefperiode</strong> is gestart!
              </p>
            </div>
          </div>
          {url && (
            <div className="mt-8 sm:flex sm:flex-row sm:justify-center">
              <Button href={url} large>
                Nu starten!
              </Button>
            </div>
          )}
          {!url && (
            <div className="mx-auto mt-4 max-w-sm">
              <p className="text-center text-base font-semibold text-primary-600">
                We hebben je een e-mail gestuurd om de omgeving verder in te stellen.
              </p>
            </div>
          )}
        </div>
      )}

      <Dialog open={open} onClose={setOpen} small>
        <div className="p-2 sm:p-6">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <UiDialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Omgeving aangemaakt!
              </UiDialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  De omgeving is succesvol aangemaakt en jouw <strong>14-dagen proefperiode</strong> is gestart!
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center">
            {url && (
              <Button href={url} large>
                Nu starten!
              </Button>
            )}
            {!url && (
              <Button large color="light">
                E-mail verstuurd!
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default CreatingForm;
